<template>
  <div id="app">
    <ImageUpload @imageUploaded="onImageUploaded" />
    <ImageList ref="imageList" />
  </div>
</template>

<script>
import ImageUpload from './components/ImageUpload.vue';
import ImageList from './components/ImageList.vue';

export default {
  components: {
    ImageUpload,
    ImageList,
  },
  methods: {
    onImageUploaded() {
      this.$refs.imageList.fetchImages();
    },
  },
};
</script>
