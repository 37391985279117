<template>
  <div>
    <h2>Upload Image</h2>
    <input type="file" @change="onFileChange" />
    <select v-model="section">
      <option value="" disabled>Select Section</option>
      <option value="home">Home</option>
      <option value="about_us">About Us</option>
      <option value="products">Products</option>
      <option value="contact">Contact</option>
      <!-- Add more sections as needed -->
    </select>
    <button @click="uploadImage">Upload</button>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';

export default {
  name: 'ImageUpload',
  setup() {
    const image = ref(null);
    const section = ref('');

    const onFileChange = (event) => {
      image.value = event.target.files[0];
    };

    const uploadImage = async () => {
      if (!section.value) {
        alert('Please select a section.');
        return;
      }

      const formData = new FormData();
      formData.append('image', image.value);
      formData.append('section', section.value);

      try {
        await axios.post('http://localhost:8000/api/images/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        alert('Image uploaded successfully!');
        window.dispatchEvent(new Event('imageUploaded'));
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Image upload failed!');
      }
    };

    return {
      image,
      section,
      onFileChange,
      uploadImage,
    };
  },
};
</script>
