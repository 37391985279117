<template>
  <div>
    <h2>Image List</h2>
    <select v-model="selectedSection" @change="fetchImages">
      <option value="" disabled>Select Section</option>
      <option value="home">Home</option>
      <option value="about_us">About Us</option>
      <option value="products">Products</option>
      <option value="contact">Contact</option>
      <!-- Add more sections as needed -->
    </select>
    <div v-for="image in images" :key="image.id">
      <img :src="image.image" alt="Uploaded Image" width="200" />
      <p>Section: {{ image.section }}</p>
      <button @click="deleteImage(image.id)">Delete</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
  name: 'ImageList',
  setup() {
    const images = ref([]);
    const selectedSection = ref('');

    const fetchImages = async () => {
      try {
        const response = await axios.get('http://localhost:8000/api/images/', {
          params: {
            search: selectedSection.value,
          },
        });
        images.value = response.data;
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    const deleteImage = async (id) => {
      try {
        await axios.delete(`http://localhost:8000/api/images/${id}/`);
        alert('Image deleted successfully!');
        await fetchImages();
      } catch (error) {
        console.error('Error deleting image:', error);
        alert('Failed to delete the image.');
      }
    };


    const getImageUrl = (path) => {
      return `http://localhost:8000${path}`;
    };

    onMounted(() => {
      fetchImages();
    });

    window.addEventListener('imageUploaded', fetchImages);

    return {
      images,
      selectedSection,
      fetchImages,
      deleteImage,
      getImageUrl,
    };
  },
};
</script>
